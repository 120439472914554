<template>
  <div>
    <window-header></window-header>
    <nav-form :onlyIcons="true"></nav-form>
    <section>
      <article style="height: 73px; width:411px">
        <field
          name="codigo"
          widget="input"
          label="Código"
          placeholder="Cod"
          width="50px"
          required
          inputStyle="font-weight:bold; text-align:center;"
          style="top:9px; left:30px;"
          searchable
          :readonly="mode=='edit'"
        />
        <field
          name="nombre"
          widget="input"
          searchable
          label="Sección"
          help="Descripción de la Sección"
          placeholder="Descripción"
          width="270px"
          style="top:9px; left:95px;"
        />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{count}}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    return {
      title: "Secciones",
      dbAdapter: "seccion",
      primary: "codigo",
      sequence: {
        name: "seccion"
      }
    };
  }
};
</script>